import { VueRenderer } from "@tiptap/vue-2";
import tippy from "tippy.js";

import MentionList from "./MentionList.vue";

export default {
  char: "/",

  items: ({ query }) => {
    return (
      [
        " Nome",
        " E-mail",
        " Data de nascimento",
        // "Lea Thompson",
        // "Cyndi Lauper",
        // "Tom Cruise",
        // "Madonna",
        // "Jerry Hall",
        // "Joan Collins",
        // "Winona Ryder",
        // "Christina Applegate",
        // "Alyssa Milano",
        // "Molly Ringwald",
        // "Ally Sheedy",
        // "Debbie Harry",
        // "Olivia Newton-John",
        // "Elton John",
        // "Michael J. Fox",
        // "Axl Rose",
        // "Emilio Estevez",
        // "Ralph Macchio",
        // "Rob Lowe",
        // "Jennifer Grey",
        // "Mickey Rourke",
        // "John Cusack",
        // "Matthew Broderick",
        // "Justine Bateman",
        // "Lisa Bonet",
      ]

        // return[
        //   {
        //     name: 'Nome',
        //     icon: 'mdi-alphabetical'
        //   },
        //   {
        //     name: 'E-mail',
        //     icon: 'mdi-email'
        //   },
        //   {
        //     name: 'Data de nascimento',
        //     icon: 'mdi-calendar'
        //   },
        // ]
        .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
        .slice(0, 5)
    );
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new VueRenderer(MentionList, {
          // using vue 2:
          parent: this,
          propsData: props,
          // using vue 3:
          // props,
          // editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });

      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        // console.log(props.event.key, "clicked key here");
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
