<template>
  <div class="card">
    <div
      v-for="item in normal_options"
      :key="item.value"
      class="item"
      @click="sendClickedInfo(item)"
    >
      <v-icon small>{{ item.icon }}</v-icon> <span>{{ item.text }}</span>
    </div>

    <v-divider style="margin-bottom: 0.5rem" />

    <div
      v-for="item in ai_options"
      :key="item.value"
      class="item"
      @click="sendClickedInfo(item)"
    >
      <v-icon small>{{ item.icon }}</v-icon> <span>{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      normal_options: [
        { action: "delete", text: "Texto", value: 0, icon: "mdi-alphabetical" },
        { action: "rename", text: "Número", value: 1, icon: "mdi-pound" },
        { action: "document", text: "Data", value: 2, icon: "mdi-calendar-blank" },
        { action: "log", text: "Url", value: 3, icon: "$link" },
      ],
      ai_options: [
        { action: "log", text: "Fórmula", value: 4, icon: " $code" },
        { action: "log", text: "Qualificar dados", value: 5, icon: "$smart_button" },
        { action: "log", text: "Zoox AI", value: 6, icon: "mdi-chip" },
      ],
    };
  },
  methods: {
    sendClickedInfo(item) {
      this.$emit("action", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.card {
  background: #fff;
  padding: 0.8rem;

  .item {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    cursor: pointer;
    span {
      font-size: 0.85rem;
    }
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
