<template>
  <div class="prompt">
    <!-- <pre>
      {{ editor.getHTML() }}
    </pre> -->
    <div v-if="isToEnablePrompt" class="prompt__inpt">
      <editor-content v-if="editor" :editor="editor" class="editor" />

      <!-- <v-textarea
        v-model="inputText"
        type="text"
        no-resize
        :rows="rowsProp"
        :hide-details="true"
        @keydown="keyDonw"
      >
      </v-textarea> -->
    </div>

    <div v-if="!isToEnablePrompt" class="prompt__inpt">
      <textarea type="text" no-resize :hide-details="true" />
    </div>

    <div class="prompt--action">
      <slot name="action" :onClick="doAction"> </slot>
    </div>

    <!-- <v-menu
      v-model="menuVisible"
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
    >
      <v-list class="prompt__card-menu">
        <v-list-item v-for="(item, index) in menuItems" :key="index">
          <v-list-item-title class="d-flex" style="gap: 0.5rem">
            <v-icon small>{{ item.icon }}</v-icon>
            <span>{{ item.text }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import suggestion from "./suggestion.js";

export default {
  props: {
    isToEnablePrompt: { type: Boolean, default: false },
    rowsProp: { type: [String, Number], default: 4 },
  },
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      chips: ["Programming", "Playing video games", "Watching movies", "Sleeping"],
      items: ["Streaming", "Eating"],
      inputText: "",
      menuVisible: false,
      menuPosition: { x: 0, y: 0 },
      menuItems: [
        {
          text: "Nome",
          id: 0,
          icon: "$zoox_user",
        },
        {
          text: "Email",
          id: 1,
          icon: "mdi-at",
        },
        {
          text: "Data de Nascimento",
          id: 2,
          icon: "mdi-calendar",
        },
      ],
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          renderHTML({ node }) {
            return `${node.attrs.label ?? node.attrs.id}`;
          },
          // renderText({ options, node }) {
          //   console.log(options, node);
          //   return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id.name}`;
          // },
          suggestion,
        }),
      ],
      content: ``,
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    onInput(e) {
      console.log(e.target.innerText);
    },
    doAction() {},
    keyDonw(e) {
      if (e.key === "/") {
        this.menuVisible = true;
        const textarea = e.target;
        const rect = textarea.getBoundingClientRect();
        const x = rect.left; // Alinha com a borda esquerda do textarea
        const y = rect.bottom - 20; // Alinha com a parte inferior do textarea
        this.menuPosition = { x, y };

        // console.log(textBeforeCaret);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.prompt {
  border-radius: 10px;
  border: 1px solid #202123;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  // &__card-menu {
  //   padding: 0.5rem;
  //   -webkit-box-shadow: -13px 12px 28px 0px rgba(0, 0, 0, 0.75);
  //   -moz-box-shadow: -13px 12px 28px 0px rgba(0, 0, 0, 0.75);
  //   box-shadow: -13px 12px 28px 0px rgba(0, 0, 0, 0.75);
  // }

  &__inpt {
    height: 100%;
    flex: 1;
    display: flex;
    @include trackScrollBar;
    flex-direction: column;

    .editor {
      height: 100%;
      // border: 2px solid red;
      display: flex;

      ::v-deep .tiptap {
        flex: 1;
        // border: 2px solid blue;
        // height: 15vh;
        @include trackScrollBar;
        overflow: auto;
        padding: 0.5rem;
        max-height: 19vh;

        &:focus {
          outline: none;
        }
      }

      ::v-deep .mention {
        border: 1.6px solid #000;
        border-radius: 15px;
        padding: 0 0.3rem;
        box-decoration-break: clone;
        font-size: 0.8rem;
        font-weight: 600;
        display: inline-block;

        &::first-letter {
          // border: 5px solid red !important;
          // color: transparent !important;
          // visibility: hidden !important;
        }
      }
    }

    textarea,
    .text-area {
      flex: 1;
      resize: none;
      box-sizing: border-box;
      @include trackScrollBar;
      padding: 0.5rem;

      &:focus {
        outline: none;
      }
    }

    // .editor {
    //   height: 19vh !important;
    //   // border: 5px solid red !important;
    //   @include trackScrollBar;
    //   overflow: auto;
    // }

    // &::v-deep .v-textarea {
    //   //   border: 3px solid green;
    //   // height: 100% !important;
    //   // flex: 1 1 100%;
    //   textarea {
    //     @include trackScrollBar;
    //     padding: 0.5rem;
    //     // display: none;
    //     // flex: 1;
    //     // height: 100%;
    //     // max-height: 20vh;
    //     // display: none;
    //     //       border: 1px solid blue;
    //     // resize: none;
    //     // overflow: auto;
    //     // border: none !important;
    //     &:focus {
    //       outline: none;
    //     }
    //   }
    // }
  }

  &--action {
    flex: 0;
    padding: 0.35rem 0.5rem;
    background: #ececec;
    display: flex;
    justify-content: flex-end;
    //  border: none !important;
  }
}
</style>
