<template>
  <v-card class="card">
    <div class="card__header">
      <div class="title">
        <span>Nova Coluna De Uma Fórmula</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="card__body">
      <div class="left">
        <div class="left__description mb-2">
          <span>Descreva como você quer sua formula</span>
        </div>

        <div class="left__square-formula">
          <div class="formula-section">
            <Prompt :is-to-enable-prompt="true">
              <template #action="props">
                <z-btn
                  text="Gerar fórmula"
                  x-small
                  rounded
                  color="#202123"
                  @click="props.onClick"
                >
                  <template #text>
                    <div class="d-flex align-center">
                      <v-icon x-small>mdi-code-braces</v-icon>
                      <span>Gerar fórmula</span>
                    </div>
                  </template>
                </z-btn>
              </template>
            </Prompt>

            <!-- <v-menu v-model="menuOpen" top :close-on-content-click="true">
              <template v-slot:activator="{ on, attrs }">
                <textarea
                  :placeholder="`'Olá' + {{ 'Nome' }}`"
                  v-model="text"
                  v-bind="attrs"
                  v-on="currentKey?.endsWith('/') ? on : null"
                  contenteditable="true"
                  @keydown="keyDonw"
                ></textarea>
              </template>

              <SelectFormulaFieldCard @click="setNewItemToForm" />
            </v-menu> -->
          </div>
          <!-- <div class="generator-section">
            <z-btn
              text="Gerar fórmula"
              x-small
              rounded
              color="#202123"
              @click="generateFormula"
            >
              <template #text>
                <div class="d-flex align-center">
                  <v-icon x-small>mdi-code-braces</v-icon>
                  <span>Gerar fórmula</span>
                </div>
              </template>
            </z-btn>
          </div> -->
        </div>

        <div class="left__description mb-2">
          <span>Digite / para incluir uma coluna</span>
          <br />
          <span>Ou escreva sua própria fórmula</span>
        </div>

        <div class="left__square-formula">
          <Prompt>
            <template #action="props">
              <z-btn
                text="Executar fórmula"
                x-small
                rounded
                color="#202123"
                @click="props.onClick"
              >
                <template #text>
                  <div class="d-flex align-center">
                    <v-icon x-small>$play_circle_outline</v-icon>
                    <span>Executar fórmula</span>
                  </div>
                </template>
              </z-btn>
            </template>
          </Prompt>

          <!-- <div class="formula-section">
            <textarea></textarea>
          </div>
          <div class="generator-section">
            <z-btn text="Executar fórmula" x-small rounded color="#202123">
              <template #text>
                <div class="d-flex align-center">
                  <v-icon x-small>$play_circle_outline</v-icon>
                  <span>Executar fórmula</span>
                </div>
              </template>
            </z-btn>
          </div> -->
        </div>
      </div>

      <div v-if="!hasFormula" class="right">
        <div class="right__title mb-2"><strong>Exemplos de Prompts</strong></div>

        <div class="right__list">
          <template v-for="(item, i) in prompts">
            <div class="item" :key="item">
              <span
                ><strong>{{ i + 1 }} -</strong> {{ item }}</span
              >
            </div>
          </template>
        </div>
      </div>

      <div v-if="hasFormula" class="right">
        <div class="right__title mb-2"><strong>Resultado da fórmula</strong></div>

        <div class="right__table">
          <z-table
            class="table"
            :headers="headers"
            :items="users_result_mock"
            icon-next="mdi-chevron-right"
            icon-previous="mdi-chevron-left"
          ></z-table>
        </div>
      </div>
    </div>

    <div class="card__footer d-flex justify-end">
      <z-btn text="Cancelar" small rounded color="#202123" @click="$emit('close')" />
    </div>
  </v-card>
</template>

<script>
// import SelectFormulaFieldCard from "./selectFormulaFieldCard.vue";
import Prompt from "./shared/Prompt.vue";
export default {
  components: {
    // SelectFormulaFieldCard,
    Prompt,
  },
  // created() {
  //   const arr = [];

  //   for (let i = 0; i < 30; i++) {
  //     arr.push({
  //       id: i,
  //       name: `Fulano da Silva teste ${1}`,
  //       email: `fulano${i}@email.com`,
  //       telefone: "+55 24999999999",
  //     });
  //   }

  //   console.log(arr);
  // },
  data() {
    return {
      hasFormula: true,
      text: "",
      currentKey: null,
      menuOpen: false,
      menuItems: ["Nome", "E-mail", "Data de Nascimento"], // Example menu items
      prompts: [
        "Quantos dias se passaram desde a primeira compra?",
        "Identifique qual o domínio da coluna E-MAIL",
        "Qual é a primeira palavra da coluna NOME e remova todos os caracteres especiais.",
        "Caso a coluna CPF esteja preenchida é Pessoa Física. Caso contrário é Pessoa Jurídica.",
        "Identifique a cidade através da palavra antes da primeira vírgula na coluna LOCALIDADE.",
      ],
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Telefone", value: "telefone", sortable: true },
      ],
      users_result_mock: [
        {
          id: 0,
          name: "Fulano da Silva teste 1",
          email: "fulano0@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 1,
          name: "Fulano da Silva teste 1",
          email: "fulano1@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 2,
          name: "Fulano da Silva teste 1",
          email: "fulano2@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 3,
          name: "Fulano da Silva teste 1",
          email: "fulano3@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 4,
          name: "Fulano da Silva teste 1",
          email: "fulano4@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 5,
          name: "Fulano da Silva teste 1",
          email: "fulano5@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 6,
          name: "Fulano da Silva teste 1",
          email: "fulano6@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 7,
          name: "Fulano da Silva teste 1",
          email: "fulano7@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 8,
          name: "Fulano da Silva teste 1",
          email: "fulano8@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 9,
          name: "Fulano da Silva teste 1",
          email: "fulano9@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 10,
          name: "Fulano da Silva teste 1",
          email: "fulano10@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 11,
          name: "Fulano da Silva teste 1",
          email: "fulano11@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 12,
          name: "Fulano da Silva teste 1",
          email: "fulano12@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 13,
          name: "Fulano da Silva teste 1",
          email: "fulano13@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 14,
          name: "Fulano da Silva teste 1",
          email: "fulano14@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 15,
          name: "Fulano da Silva teste 1",
          email: "fulano15@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 16,
          name: "Fulano da Silva teste 1",
          email: "fulano16@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 17,
          name: "Fulano da Silva teste 1",
          email: "fulano17@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 18,
          name: "Fulano da Silva teste 1",
          email: "fulano18@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 19,
          name: "Fulano da Silva teste 1",
          email: "fulano19@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 20,
          name: "Fulano da Silva teste 1",
          email: "fulano20@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 21,
          name: "Fulano da Silva teste 1",
          email: "fulano21@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 22,
          name: "Fulano da Silva teste 1",
          email: "fulano22@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 23,
          name: "Fulano da Silva teste 1",
          email: "fulano23@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 24,
          name: "Fulano da Silva teste 1",
          email: "fulano24@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 25,
          name: "Fulano da Silva teste 1",
          email: "fulano25@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 26,
          name: "Fulano da Silva teste 1",
          email: "fulano26@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 27,
          name: "Fulano da Silva teste 1",
          email: "fulano27@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 28,
          name: "Fulano da Silva teste 1",
          email: "fulano28@email.com",
          telefone: "+55 24999999999",
        },
        {
          id: 29,
          name: "Fulano da Silva teste 1",
          email: "fulano29@email.com",
          telefone: "+55 24999999999",
        },
      ],
    };
  },
  watch: {
    text(value) {
      if (value.endsWith("/")) {
        this.menuOpen = true;
      } else {
        this.menuOpen = false;
      }
    },
  },
  methods: {
    generateFormula() {},
    setNewItemToForm(item) {
      console.log(item);
    },
    keyDonw(e) {
      console.log(e.key);
      this.currentKey = e.key;
    },
    selectItem() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.card {
  padding: 1rem;
  height: 80vh;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    flex: 1;
    font-size: 0.9rem;
    display: flex;
    gap: 1rem;
    overflow: auto;
    @include trackScrollBar;

    & > div {
      flex: 1 1 500px;
    }

    .left {
      &__square-formula {
        // border: 2px solid #202123;
        height: 25vh;
        border-radius: 10px;
        // display: flex;
        // flex-direction: column;

        position: relative;
        overflow: hidden;

        .formula-section {
          height: 100%;
          flex: 1;
          // overflow: hidden;
          // @include trackScrollBar;
          // border: 5px solid blue;

          textarea,
          .text-area {
            width: 100%;
            height: 100%;
            resize: none;
            box-sizing: border-box;
            @include trackScrollBar;
            padding: 0.5rem 0.5rem 2rem;

            &:focus {
              outline: none;
            }
          }
          // input {
          //   border: 1px solid red;
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .generator-section {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 0.2rem 0.5rem;
          background: #ececec;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      &__list {
        .item {
          &:not(:last-child) {
            margin-bottom: 0.8rem;
          }
        }
      }

      &__table {
        // flex: 1 1 100%;
        @include trackScrollBar2;
        overflow: auto;
        height: 50vh;
      }
    }
  }
}
</style>
