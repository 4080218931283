<template>
  <v-card class="card">
    <div class="card__body">
      <div class="selector">
        <div
          class="physics"
          :class="{ active: activeButton == 0 }"
          @click="activeButton = 0"
        >
          <span>Arquivos</span>
        </div>
        <div
          class="legal"
          :class="{ active: activeButton == 1 }"
          @click="activeButton = 1"
        >
          <span>Templates</span>
        </div>
      </div>

      <div class="card__body__items">
        <template v-for="element in infos">
          <div
            :key="element.id"
            v-if="activeButton == 0 && element.type == 'file'"
            class="element"
          >
            <span class="element__title"> {{ element.title }}</span>

            <div class="element__childs mt-2">
              <template v-for="child in element.childs">
                <div
                  v-if="!child.is_to_create_new_info"
                  class="child normal"
                  :key="child.id"
                >
                  <div class="normal__header">
                    <div class="left d-flex align-center">
                      <v-icon color="#202123"> $star_outline</v-icon>
                      <span>{{ child.title }}</span>
                    </div>
                    <div class="right">
                      <v-icon color="#202123">mdi-dots-vertical</v-icon>
                    </div>
                  </div>

                  <div class="normal__body">
                    <figure></figure>
                    <div class="infos">
                      <div v-if="child.created" class="item">
                        <v-icon small>mdi-calendar </v-icon>
                        <span
                          >Criado em: <strong>{{ child.created }}</strong>
                        </span>
                      </div>
                      <div v-if="child.users" class="item">
                        <v-icon small>$zoox_user </v-icon>
                        <span
                          >Usuários únicos: <strong>{{ child.users }}</strong>
                        </span>
                      </div>
                      <div v-if="child.connection" class="item">
                        <v-icon small>mdi-ethernet-cable </v-icon>
                        <span
                          >Conexão: <strong>{{ child.connection }}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else class="child new-info" :key="child.id">
                  <figure></figure>

                  <span>{{ child.title }}</span>
                </div>
              </template>
            </div>
          </div>
          <div
            :key="element.id"
            v-else-if="activeButton == 1 && element.type == 'template'"
            class="element"
          >
            <span class="element__title"> {{ element.title }}</span>

            <div class="element__childs mt-2">
              <template v-for="child in element.childs">
                <div
                  v-if="!child.is_to_create_new_info"
                  class="child normal"
                  :key="child.id"
                >
                  <div class="normal__header">
                    <div class="left d-flex align-center">
                      <v-icon color="#202123"> $star_outline</v-icon>
                      <span>{{ child.title }}</span>
                    </div>
                    <div class="right">
                      <v-icon color="#202123">mdi-dots-vertical</v-icon>
                    </div>
                  </div>

                  <div class="normal__body">
                    <figure></figure>
                    <div class="infos">
                      <div v-if="child.created" class="item">
                        <v-icon small>mdi-calendar </v-icon>
                        <span
                          >Criado em: <strong>{{ child.created }}</strong>
                        </span>
                      </div>
                      <div v-if="child.users" class="item">
                        <v-icon small>$zoox_user </v-icon>
                        <span
                          >Usuários únicos: <strong>{{ child.users }}</strong>
                        </span>
                      </div>
                      <div v-if="child.connection" class="item">
                        <v-icon small>mdi-ethernet-cable </v-icon>
                        <span
                          >Conexão: <strong>{{ child.connection }}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else class="child new-info" :key="child.id">
                  <figure></figure>

                  <span>{{ child.title }}</span>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="card__footer d-flex justify-end">
      <z-btn text="Fechar" small rounded color="#202123" @click="$emit('close')"> </z-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      activeButton: 0,
      infos: [
        {
          id: 0,
          title: "Dashboards disponíveis para essa conexão",
          type: "file",
          childs: [
            {
              id: 0,
              title: "Dashboard 360",
              created: "20/01/2024",
              users: "28.600",
              connection: "Base vendas",
              img_path: "",
              is_to_create_new_info: false,
            },
            {
              id: 240,
              title: "Criar novo dashboard",
              created: "20/01/2024",
              users: "28.600",
              connection: "Base vendas",
              img_path: "",
              is_to_create_new_info: true,
            },
          ],
        },
        {
          id: 1,
          title: "Lista de templates",
          type: "template",
          childs: [
            {
              id: 33,
              title: "Vendas",
              created: null,
              users: "28.600",
              connection: "Base vendas",
              img_path: "",
              is_to_create_new_info: false,
            },
            {
              id: 932,
              title: "Vendas site",
              created: "",
              users: "28.600",
              connection: "Base vendas",
              img_path: "",
              is_to_create_new_info: false,
            },
          ],
        },
      ],
    };
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.card {
  padding: 1rem;
  height: 60vh;
  height: 70dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #202123;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__body {
    flex: 1;
    overflow-y: auto;
    @include trackScrollBar;

    .selector {
      border: 1px solid;
      width: auto !important;
      display: inline-flex;
      align-items: center;
      gap: 0.2rem;
      padding: 0.2rem;
      border-radius: 55px;

      & > div {
        cursor: pointer;
        border: 1px solid transparent;
        padding: 0 0.5rem;
        border-radius: 15px;
        span {
          font-size: 0.8rem;
        }
      }

      .active {
        // border: 1px solid #202123;
        background: #202123;
        color: #fff;
      }
    }

    &__items {
      .element {
        margin: 1rem 0;
        padding: 0.5rem;

        &__childs {
          max-width: 80%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 1rem;

          .child {
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            padding: 1rem;
            border-radius: 15px;
          }

          .normal {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;
            &__header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .left {
                gap: 0.5rem;

                span {
                  font-weight: 600;
                }
              }
            }

            &__body {
              display: flex;
              align-items: center;
              gap: 0.9rem;
              figure {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: #cdcdcd;
              }

              .infos {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 0.3rem;

                .item {
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;

                  span {
                    font-size: 0.8rem;
                  }
                }
              }
            }
          }

          .new-info {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            flex-direction: column;
            figure {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background: #cdcdcd;
            }
          }
        }
      }
    }
  }
}
</style>
