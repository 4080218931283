<template>
  <div class="step-two">
    <div class="descriptions">
      <p>Escolha a chave que será usada para a qualificação</p>
    </div>

    <div class="buttons-list">
      <template v-for="item in buttons_list">
        <div
          class="item"
          :class="{ active: activeButton?.id == item.id }"
          :key="item.id"
          @click="activeButton = item"
        >
          <div class="content">
            <figure>
              <img :src="item.img_path" :alt="item.name" />
            </figure>

            <span>
              {{ item.name }}
            </span>
          </div>
          <div class="checked">
            <div class="circle">
              <v-icon v-if="activeButton?.id == item.id" color="#0273c3">$check</v-icon>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeButton: null,
      buttons_list: [
        {
          name: "E-mail",
          img_path: "/icons/email-qualification-icon.svg",
          id: 0,
        },
        {
          name: "Linkedin",
          img_path: "/icons/badge-qualification-icon.svg",
          id: 1,
        },
        {
          name: "CPF",
          img_path: "/icons/building-qualification-icon.svg",
          id: 3,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.step-two {
  .descriptions {
    p {
      font-size: 0.9rem;
    }
  }
  .buttons-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;

    .item {
      display: flex;
      padding: 0.5rem;
      border-radius: 10px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

      .content {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .checked {
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid;
        }
      }

      // & > div {
      //   border: 1px solid;
      // }
    }

    .active {
      background: #0273c3;
      color: white;

      figure {
        img {
          filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg)
            brightness(110%) contrast(110%);
        }
      }

      .checked {
        .circle {
          background: white;
        }
      }
    }
  }
}
</style>
