<template>
  <div class="step-one">
    <div class="descriptions">
      <p>Escolha as informações que você deseja qualificar.</p>
      <p>
        Sua tabela será <strong>recarregada</strong> com as
        <strong>colunas enriquecidas selecionadas abaixo.</strong>
      </p>
    </div>

    <div class="selector">
      <div
        class="physics"
        :class="{ active: activeButton == 0 }"
        @click="activeButton = 0"
      >
        <span>Pessoa Física</span>
      </div>
      <div class="legal" :class="{ active: activeButton == 1 }" @click="activeButton = 1">
        <span>Pessoa Jurídica</span>
      </div>
    </div>

    <div v-if="activeButton == 0" class="elements">
      <template v-for="chip in physics_list">
        <div
          class="chip"
          :class="{ active: foundInformation(chip) }"
          :key="chip.id"
          @click="insertOrRemoveQualification(chip)"
        >
          <v-icon small>{{ chip.icon }}</v-icon> <span>{{ chip.name }}</span>
        </div>
      </template>
    </div>

    <div v-if="activeButton == 1" class="elements">
      <template v-for="chip in legal_list">
        <div
          class="chip"
          :class="{ active: foundInformation(chip) }"
          :key="chip.id"
          @click="insertOrRemoveQualification(chip)"
        >
          <v-icon small>{{ chip.icon }}</v-icon> <span>{{ chip.name }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeButton: 0,
      physics_list: [
        {
          name: "Dados Cadastrais",
          id: 0,
          selected: false,
          icon: "$badge",
        },
        {
          name: "Telefone",
          id: 1,
          selected: false,
          icon: "mdi-phone",
        },
        {
          name: "E-mail",
          id: 2,
          selected: false,
          icon: "mdi-at",
        },
        {
          name: "Endereço",
          id: 3,
          selected: false,
          icon: "mdi-map-marker",
        },
        {
          name: "Renda",
          id: 4,
          selected: false,
          icon: "$attach_money",
        },
        {
          name: "Cargo Profissional",
          id: 5,
          selected: false,
          icon: "mdi-medical-bag",
        },
        {
          name: "Educação",
          id: 6,
          selected: false,
          icon: "mdi-school",
        },
        {
          name: "Assistente Social",
          id: 7,
          selected: false,
          icon: "$gavel",
        },
        {
          name: "PEP",
          id: 8,
          selected: false,
          icon: "$zoox_user",
        },
        {
          name: "Processos Judiciais",
          id: 9,
          selected: false,
          icon: "$gavel",
        },
        {
          name: "Veículos",
          id: 10,
          selected: false,
          icon: "mdi-car",
        },
        {
          name: "Servidor Público",
          id: 11,
          selected: false,
          icon: "$people_outline_rounded",
        },
        {
          name: "Imagem de Perfil",
          id: 12,
          selected: false,
          icon: "$face",
        },
        {
          name: "Dados de Crédito",
          id: 13,
          selected: false,
          icon: "$credit_card_rounded",
        },
        {
          name: "Doações Políticas",
          id: 14,
          selected: false,
          icon: "mdi-heart",
        },
        {
          name: "Empresas Societárias",
          id: 15,
          selected: false,
          icon: "$handshake_outline",
        },
      ],
      legal_list: [
        {
          name: "Dados Cadastrais",
          id: 0,
          selected: false,
          icon: "$badge",
        },
        {
          name: "Faturamento",
          id: 1,
          selected: false,
          icon: "$monetization_on",
        },
        {
          name: "Dados de Contato",
          id: 2,
          selected: false,
          icon: "$contact_phone",
        },
        {
          name: "QSA",
          id: 3,
          selected: false,
          icon: "$people_outline_rounded",
        },
        {
          name: "Dados de Localização",
          id: 4,
          selected: false,
          icon: "mdi-map-marker",
        },
        {
          name: "Processos Judiciais",
          id: 5,
          selected: false,
          icon: "$gavel",
        },
        {
          name: "Logo da Empresa",
          id: 6,
          selected: false,
          icon: "$image_rounded",
        },
        {
          name: "Compilance & PLD",
          id: 7,
          selected: false,
          icon: "$gavel",
        },
        {
          name: "Empresas Societárias",
          id: 8,
          selected: false,
          icon: "$handshake_outline",
        },
      ],
      qualifications_set: [],
    };
  },
  watch: {
    activeButton() {
      this.qualifications_set = [];
    },
  },
  methods: {
    insertOrRemoveQualification(info) {
      const founded_qualification = this.foundInformation(info);

      if (founded_qualification) {
        const idx = this.qualifications_set.findIndex((el) => el.id == info.id);
        this.qualifications_set.splice(idx, 1);
      } else {
        this.qualifications_set.push(info);
      }
    },
    foundInformation(info) {
      const founded = this.qualifications_set.find((d) => d.id == info.id);

      return founded ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.step-one {
  .descriptions {
    p {
      font-size: 0.9rem;
    }
  }
  .selector {
    border: 1px solid;
    width: auto !important;
    display: inline-flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0.2rem;
    border-radius: 55px;

    & > div {
      cursor: pointer;
      border: 1px solid transparent;
      padding: 0 0.5rem;
      border-radius: 15px;
      span {
        font-size: 0.8rem;
      }
    }

    .active {
      // border: 1px solid #202123;
      background: #202123;
      color: #fff;
    }
  }
  .elements {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .chip {
      cursor: pointer;
      color: #202123;
      border: 1px solid #202123;
      padding: 0.1rem 0.8rem;
      border-radius: 25px;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
        font-size: 0.9rem;
      }
      i {
        color: #202123;
      }
    }

    .active {
      background: #202123;
      color: #fff;
      i {
        color: #fff;
      }
    }
  }
}
</style>
