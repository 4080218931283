<template>
  <v-card class="card">
    <div class="card__header mb-2">
      <div class="title">
        <span>Qualificação</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="card__body">
      <component :is="components_list[currentStepIndex]" />
    </div>

    <div class="card__footer d-flex align-center justify-end mt-3" style="gap: 0.5rem">
      <!-- <z-btn
        text="Voltar"
        small
        rounded
        primary
        color="#202123"
        style="color: #fff"
        @click="currentStepIndex--"
      /> -->
      <z-btn text="Cancelar" small rounded color="#202123" />
      <z-btn
        :text="buttonTitle"
        rounded
        small
        primary
        color="#202123"
        style="color: #fff"
        @click="goSteps"
      />
    </div>

    <z-card-modal v-model="confirmationDialog" title="" width="450">
      <template #content>
        <div class="info-modal">
          <div class="title mb-4">
            <span>Criação De Colunas Qualificadas</span>
          </div>

          <div class="drescription">
            <p>
              Setão criadas <strong>2 novas colunas</strong> com dados:
              <strong>"E-mail" + "Telefone"</strong>
            </p>

            <p>Total de créditos a serem usados: <strong>1000 créditos.</strong></p>

            <p>Deseja prosseguir?</p>

            <div class="actions d-flex justify-end" style="gap: 0.5rem">
              <z-btn
                text="Cancelar"
                small
                rounded
                color="#202123"
                @click="closeConfirmation"
              />
              <z-btn
                text="Sim"
                rounded
                small
                primary
                color="#202123"
                style="color: #fff"
                @click="closeConfirmation"
              />
            </div>
          </div>
        </div>
      </template>
    </z-card-modal>
  </v-card>
</template>

<script>
import QualificationStepOne from "./qualificationStepOne.vue";
import QualificationStepTwo from "./qualificationStepTwo.vue";
import QualificationStepThree from "./qualificationStepThree.vue";
export default {
  components: {
    QualificationStepOne,
    QualificationStepTwo,
    QualificationStepThree,
  },
  data() {
    return {
      confirmationDialog: false,
      currentStepIndex: 0,
      components_list: [
        "QualificationStepOne",
        "QualificationStepTwo",
        "QualificationStepThree",
      ],
    };
  },
  computed: {
    buttonTitle() {
      return this.currentStepIndex == this.components_list.length - 1
        ? "Qualificar Selecionados"
        : "Avançar";
    },
  },
  methods: {
    closeConfirmation() {
      this.confirmationDialog = false;
      this.$emit("close");
    },
    goSteps() {
      if (this.currentStepIndex < this.components_list.length - 1) {
        this.currentStepIndex++;
        return;
      }
      // último step
      if (this.currentStepIndex == this.components_list.length - 1) {
        this.confirmationDialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.card {
  padding: 1rem;
  height: 80vh;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__body {
    flex: 1;
    overflow-y: auto;
    @include trackScrollBar2;
  }
}
</style>
